import React from "react";
import { BsChevronBarContract } from "react-icons/bs";
import { IoDiamondOutline } from "react-icons/io5";
import { IoThermometerOutline } from "react-icons/io5";
import { IoTimeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
function Specifications() {
  const { t } = useTranslation();
  return (
    <div className="specifications-block-wrapper" id="section1">
      <div className="specifications-container">
        <ScrollAnimation
          className="specifications-text-wrapper"
          animateIn="fadeInLeft"
          animateOut="fadeOut"
        >
          <h2 style={{ color: "#ff7a22" }}>{t("specifications.title")}</h2>
          <p className="specifications-text">{t("specifications.text")}</p>
        </ScrollAnimation>
        <div className="specifications-wrapper">
          <div className="specifications-items-wrapper">
            <div className="specifications-items">
              <div className="specifications-items-iconWrapper">
                <BsChevronBarContract />
              </div>
              <h4>{t("specifications.item1.title")}</h4>
              <hr className="specifications-items-line" />
              <p>{t("specifications.item1.description")}</p>
            </div>
            <div className="specifications-items">
              <div className="specifications-items-iconWrapper">
                <IoDiamondOutline />
              </div>
              <h4>{t("specifications.item2.title")}</h4>
              <hr className="specifications-items-line" />
              <p>{t("specifications.item2.description")}</p>
            </div>
          </div>
          <div className="specifications-items-wrapper">
            <div className="specifications-items">
              <div className="specifications-items-iconWrapper">
                <IoThermometerOutline />
              </div>
              <h4>{t("specifications.item3.title")} </h4>
              <hr className="specifications-items-line" />
              <p>{t("specifications.item3.description")}</p>
            </div>
            <div className="specifications-items">
              <div className="specifications-items-iconWrapper">
                <IoTimeOutline />
              </div>
              <h4>{t("specifications.item4.title")}</h4>
              <hr className="specifications-items-line" />
              <p>{t("specifications.item4.description")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specifications;
