import React, { useEffect, useState } from "react";
import logo from "../../styles/images/logo-profservice.png";
import { Link } from "react-scroll";
import LangSelect from "../langSelect";
import { useTranslation } from "react-i18next";
import axios from "axios";
function Header(props) {
  const requestUrl = props.requestUrl;
  const { t, i18n } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [phone, setPhone] = useState("");
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  useEffect(() => {
    if (i18n.language === "ua") {
      axios
        .get(`${requestUrl}companyDataUa`)
        .then((response) => {
          setPhone(response.data[0].phone);
        });
    } else {
      axios
        .get(`${requestUrl}companyDataRu`)
        .then((response) => {
          setPhone(response.data[0].phone);
        });
    }
  }, [i18n.language]);

  return (
    <div className={offset > 0 ? "header-scroll-wrapper" : "header-wrapper"}>
      <div className="header-container">
        <Link
          activeClass="active"
          to="section1"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <div className="header-logo-container">
            <img
              src={logo}
              alt={"logo"}
              className={offset > 0 ? "header-scroll-logo" : "header-logo"}
            />
          </div>
        </Link>
        <nav className="header-navbar" id="navbar">
          <ul className="header-nav-items">
            <li className="header-nav-item">
              <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("header.about")}
              </Link>
            </li>
            <li className="header-nav-item">
              <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("header.prices")}
              </Link>
            </li>
            <li className="header-nav-item">
              <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("header.cooperation.part1")}
                <br />
                {t("header.cooperation.part2")}
              </Link>
            </li>
            <li className="header-nav-item">
              <Link
                activeClass="active"
                to="section6"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("header.contacts")}
              </Link>
            </li>
            <li className="header-nav-item">
              <a className="header-contacts-buttonLink" href={`tel:${phone}`}>
                {phone}
              </a>
            </li>
            <li className="header-nav-item">
              <LangSelect scroll={offset} />
            </li>
          </ul>
        </nav>
        <div></div>
      </div>
    </div>
  );
}

export default Header;
