import React from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
function OrderSuccessPage() {
  const { t } = useTranslation();
  return (
    <div className="orderSuccessPage-wrapper">
      <div className="orderSuccessPage-container">
        <h2 style={{ color: "#ff7a22" }}>{t("OrderSuccessPage.title")}</h2>
        <div>
          <IoCheckmarkCircleOutline
            style={{ fontSize: "20ch", color: "#ff7a22" }}
          />
        </div>
        <div className="orderSuccessPage-textContainer">
        {t("OrderSuccessPage.message.part1")}
          <br />
          {t("OrderSuccessPage.message.part2")}
        </div>
        <Link to="/" className="orderSuccessPage-backButton">
          <IoArrowBackOutline style={{ fontSize: "20px" }} />
          {t("OrderSuccessPage.backButton")}
        </Link>
      </div>
    </div>
  );
}

export default OrderSuccessPage;
