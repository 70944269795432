import React, { useState } from "react";
import { IoCall } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
function BackCall(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onBlur",
  });
  const handleBoxToggle = () => {
    setShowBox(!showBox);
    console.log(showBox);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowBox(false);
  };
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", phone: "" });
    }
  }, [formState, reset]);
  const submitForm = async (data) => {
    data.state = "Не оброблено❌";
    data.emailToSend = emailToSend;
    setAlertSuccessOpen(false);
    setAlertErrorOpen(false);
    try {
      await axios
        .post(`${requestUrl}back-call`, data)
        .then((res) => {
          console.log(res);
          setAlertSuccessOpen(true);
        });
    } catch (error) {
      console.log(error);
      setAlertErrorOpen(true);
    }
  };
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      const a = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      return a;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  return (
    <React.Fragment>
      <div
        onMouseEnter={handleBoxToggle}
        onMouseLeave={handleBoxToggle}
        onClick={handleClickOpen}
        className="backCall"
      >
        <IoCall style={{ fontSize: "30px", color: "white" }} />
      </div>
      <div className={`backCall-container${showBox ? " show" : ""}`}>
        {t("backCall.hover")}
        <IoChevronForward style={{ fontSize: "30px", color: "#ff7a22" }} />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className="backCall-dialog-container">
          <h2>{t("backCall.title")}</h2>
          <form
            onSubmit={handleSubmit(submitForm)}
            className={
              isSubmitting ? "partner-form _sendingFile" : "partner-form"
            }
          >
            <label className="partner-inputLabel">
              &nbsp;&nbsp;{t("backCall.form.name.label")}
              <input
                placeholder={t("backCall.form.name.placeholder")}
                className="partner-input"
                type="text"
                name="name"
                {...register("name", {
                  required: `${t("backCall.form.errors.name")}`,
                  minLength: {
                    value: 3,
                    message: `${t("partner.form.errors.name.minLength")}`,
                  },
                })}
              />
              {errors.name && (
                <p className="partner-input-error">
                  &nbsp;&nbsp;{errors.name.message || "Error!"}
                </p>
              )}
            </label>
            <label className="partner-inputLabel">
              &nbsp;&nbsp;{t("backCall.form.phone")}
              <input
                className="partner-input"
                name="phone"
                placeholder="(099)-999-9999"
                {...register("phone", {
                  required: `${t("backCall.form.errors.phone.required")}`,
                  pattern: {
                    value:
                    /^(?!\(.*\).*--.*$)(?!\(.*\).*-$)(\(0[0-9]{1}[0-9]{1}\) [0-9]{1}[0-9]{1}[0-9]{1}[-]{1}[0-9]{1}[0-9]{1}[0-9]{1}[0-9]{1})$/,
                    message: `${t("orderingPage.phoneError")}`,
                  },
                  minLength: {
                    value: 14,
                    message: `${t("orderingPage.phoneMinError")}`,
                  },
                })}
                onChange={(e) => {
                  setValue("phone", formatPhoneNumber(e.target.value));
                }}
              />
              {errors.phone && (
                <p className="partner-input-error">
                  &nbsp;&nbsp;{errors.phone.message || "Error!"}
                </p>
              )}
            </label>
            <div className="backCall-buttons-wrapper">
              <button
                type="button"
                onClick={handleClose}
                className="partner-formButton"
              >
                {t("backCall.form.buttons.back")}
              </button>
              <button
                disabled={!isValid}
                type="submit"
                className="partner-formButton"
              >
                {t("backCall.form.buttons.submit.part1")}
                <br />
                {t("backCall.form.buttons.submit.part2")}
              </button>
            </div>
          </form>
          <Collapse className="backCall-alert" in={alertSuccessOpen}>
            <Alert
              severity="success"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertSuccessOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("inputFile.alertSuccess")}
            </Alert>
          </Collapse>
          <Collapse className="backCall-alert" in={alertErrorOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertErrorOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("inputFile.alertError")}
            </Alert>
          </Collapse>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default BackCall;
