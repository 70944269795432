import React, { useMemo, useRef, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import ScrollAnimation from "react-animate-on-scroll";
import slide1 from "../../styles/images/slide1.jpg";
import slide2 from "../../styles/images/slide2.jpg";
import slide3 from "../../styles/images/slide3.jpg";
import slide4 from "../../styles/images/slide4.jpg";
import slide5 from "../../styles/images/slide5.jpg";
import slide6 from "../../styles/images/slide6.jpg";
import { useTranslation, } from "react-i18next";
function Slider() {
  const { t, i18n } = useTranslation();
  const slides = useMemo(() => {
    return [
    {
      id: "0",
      image: slide1,
      discription: t("slides.slide1"),
    },
    {
      id: "1",
      image: slide2,
      discription: t("slides.slide2"),
    },
    {
      id: "2",
      image: slide3,
      discription: t("slides.slide3"),
    },
    {
      id: "3",
      image: slide4,
      discription: t("slides.slide4"),
    },
    {
      id: "4",
      image: slide5,
      discription: t("slides.slide5"),
    },
    {
      id: "5",
      image: slide6,
      discription: t("slides.slide6"),
    },
  ]}, [t,i18n.language]);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 901px)",
  });
  const tablet = useMediaQuery({
    query: "(max-width: 900px) and (min-width:500px)",
  });
  const mobile = useMediaQuery({ query: "(max-width: 499px)" });

  const slider = useRef(null);

  let [position, setPosition] = useState(0);
  const prevHandler = () => {
    if (position === 0) {
      return;
    } else {
      setPosition((position += 250));

      slider.current.childNodes.forEach((element) => {
        element.style = `transform: translateX(${position}px)`;
      });
    }
  };
  const nextHandler = () => {
    if (position === (slides.length - 3) * 200 + 200) {
      return;
    } else {
      setPosition((position -= 250));
      slider.current.childNodes.forEach((element) => {
        element.style = `transform: translateX(${position}px)`;
      });
    }
    console.log(position);
  };

  const mobilePrevHandler = () => {
    if (position === 0) {
      return;
    } else {
      setPosition((position += 355));

      slider.current.childNodes.forEach((element) => {
        element.style = `transform: translateX(${position}px)`;
      });
    }
  };
  const mobileNextHandler = () => {
    if (position === (slides.length - 3) * 200 + 200) {
      return;
    } else {
      setPosition((position -= 355));
      slider.current.childNodes.forEach((element) => {
        element.style = `transform: translateX(${position}px)`;
      });
    }
    console.log(position);
  };

  return (
    <div className="slider-wrapper">
      <div className="slider-container">
        <ScrollAnimation
          className="slider-button-container"
          animateIn="fadeInLeft"
          animateOut="fadeOut"
        >
          {isDesktopOrLaptop && (
            <button
              disabled={position === 0}
              className="slider-button-right"
              onClick={prevHandler}
            >
              <IoArrowBackOutline style={{ fontSize: "25px" }} />
            </button>
          )}
          {tablet && (
            <button
              disabled={position === 0}
              className="slider-button-right"
              onClick={prevHandler}
            >
              <IoArrowBackOutline style={{ fontSize: "25px" }} />
            </button>
          )}
          {mobile && (
            <button
              disabled={position === 0}
              className="slider-button-right"
              onClick={mobilePrevHandler}
            >
              <IoArrowBackOutline style={{ fontSize: "25px" }} />
            </button>
          )}
          {isDesktopOrLaptop && (
            <button
              disabled={position === -1500}
              className="slider-button-left"
              onClick={nextHandler}
            >
              <IoArrowForwardOutline
                style={{ color: "white", fontSize: "25px" }}
              />
            </button>
          )}
          {tablet && (
            <button
              disabled={position === -1750}
              className="slider-button-left"
              onClick={nextHandler}
            >
              <IoArrowForwardOutline
                style={{ color: "white", fontSize: "25px" }}
              />
            </button>
          )}
          {mobile && (
            <button
              disabled={position === -1775}
              className="slider-button-left"
              onClick={mobileNextHandler}
            >
              <IoArrowForwardOutline
                style={{ color: "white", fontSize: "25px" }}
              />
            </button>
          )}
        </ScrollAnimation>
        <ScrollAnimation
          className="slider-track-wrapper"
          animateIn="fadeInRight"
          animateOut="fadeOut"
        >
          <div className="slider-track" ref={slider}>
            {slides.map((slides) => {
              return (
                <div key={slides.id} className="slider-item">
                  <img className="slider-item-img" src={slides.image} alt="" />
                  <p className="slider-item-text">{slides.discription}</p>
                </div>
              );
            })}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}
export default Slider;
