import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function LangSelect(props) {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(i18n.language);
  const handleChange = (event) => {
    setValue(event.value);
  };
  useEffect(() => {
    i18n.changeLanguage(value);
  }, [i18n, value]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      color: state.isSelected ? "#ff7a22" : "black",
      padding: 20,
    }),
    control: () => ({
      color: "transparent",
      textShadow: "0 0 0 black",
      width: 100,
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
    }),
    input: () => ({
      display: "none",
    }),

    singleValue: (provided, state) => ({
      color: props.scroll > 0 ? "black" : "white",
    }),
  };

  const options = [
    { value: "ua", label: "UA" },
    { value: "ru", label: "RU" },
  ];

  return (
    <div>
      <Select
        className={props.scroll > 0 ? "langSelect-scroll" : "langSelect"}
        options={options}
        value={options.filter(function (option) {
          return option.value === value;
        })}
        styles={customStyles}
        onChange={handleChange}
      />
    </div>
  );
}

export default LangSelect;
// <select
//   className={props.scroll > 0 ? "langSelect-scroll" : "langSelect"}
//   value={value}
//   onChange={handleChange}
// >
//   <option className="langSelect-option" value="ua">
//     UA
//   </option>
//   <option className="langSelect-option" value="ru">
//     RU
//   </option>
// </select>
