import React from "react";
import logo from "../../styles/images/logo-profservice.png";
import LangSelect from "../langSelect";
import MobileHeaderMenu from "../mobileHeaderMenu/";
function MobileHeader(props) {
  const requestUrl = props.requestUrl;
  return (
    <div className="mobileHeader-wrapper">
      <div className="mobileHeader">
        <MobileHeaderMenu requestUrl={requestUrl}/>
        <img src={logo} alt={"logo"} className="mobileHeader-logo" />
        <LangSelect />
      </div>
    </div>
  );
}

export default MobileHeader;
