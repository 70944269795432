import React, { useState } from "react";
import InputFile from "../inputFile/";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

function SendFileForPrice(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend;
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const [filesCout, setFilesCount] = useState(0);
  const [errMessageShow, setErrMessageShow] = useState(false);

  function validatePhone(phone) {
    const phoneArr = phone.split("");
    if (phoneArr[1] == 0) {
      setErrMessageShow(false);
      return true;
    }
    setErrMessageShow(true);
    return false;
  }
  const handlePhone = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  const submitForm = async (e) => {
    console.log(phone);
    e.preventDefault();
    setAlertSuccessOpen(false);
    setAlertErrorOpen(false);
    const target = e.currentTarget;
    target.classList.add("_sendingFile");
    try {
      const data = new FormData();
      data.append("phone", phone);
      data.append("file", selectedFile);
      data.append("emailToSend", emailToSend);
      data.append("state", "Не оброблено❌");
      await axios
        .post(`${requestUrl}send-file`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          setSelectedFile(null);
          setPhone("");
          setFilesCount(0);
          target.classList.remove("_sendingFile");
          setAlertSuccessOpen(true);
        });
    } catch (error) {
      setAlertErrorOpen(true);
      target.classList.remove("_sendingFile");
    }
  };

  function SubmitButton() {
    if (selectedFile == null) {
      return null;
    }
    if (validatePhone(phone) & (phone.length > 13)) {
      return (
        <button className="sendFileForPrice-submitButton" type="submit">
          Дізнатись ціну
        </button>
      );
    }
    return (
      <button disabled className="sendFileForPrice-submitButton" type="submit">
        Дізнатись ціну
      </button>
    );
  }
  return (
    <div className="sendFileForPrice-wrapper">
      <h3 style={{ marginTop: "0" }}>{t("inputFile.title")}</h3>
      <form onSubmit={submitForm} className="sendFileForPrice-form">
        <InputFile
          onFileSelect={(file) => setSelectedFile(file)}
          filesCout={filesCout}
          setFilesCount={setFilesCount}
        />
        <div className="sendFileForPrice-container">
          <input
            placeholder="(099)-999-9999"
            className="sendFileForPrice-telInput"
            value={phone}
            onChange={(e) => handlePhone(e)}
          />
          <SubmitButton />
        </div>
        {errMessageShow ? (
          <p style={{ margin: "0", marginLeft: "10px", fontSize: "12px" }}>
            {t("orderingPage.phoneError")}
          </p>
        ) : null}
      </form>
      <Collapse className="sendFileForPrice-alert" in={alertSuccessOpen}>
        <Alert
          severity="success"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertSuccessOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {t("inputFile.alertSuccess")}
        </Alert>
      </Collapse>
      <Collapse className="sendFileForPrice-alert" in={alertErrorOpen}>
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertErrorOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {t("inputFile.alertError")}
        </Alert>
      </Collapse>
    </div>
  );
}

export default SendFileForPrice;
