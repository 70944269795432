import React from "react";
import Header from "../../components/header/";
import TopScreen from "../../components/topScreen/";
import WeOffer from "../../components/weOffer/";
import OrderingButton from "../../components/orderingButton/";
import About from "../../components/about/";
import DoublePolished from "../../components/doublePolished/";
import Specifications from "../../components/specifications/";
import Prices from "../../components/prices/";
import Partner from "../../components/partner/";
import Footer from "../../components/footer/";
import MobileHeader from "../../components/mobileHeader/";
import { useMediaQuery } from "react-responsive";
import BackCall from "../../components/backCall";
function LandingPage(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend;
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1225px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className="landingPage-wrapper">
      {isTabletOrMobile && <MobileHeader requestUrl={requestUrl} />}
      {isDesktopOrLaptop && <Header requestUrl={requestUrl} />}
      <BackCall emailToSend={emailToSend} requestUrl={requestUrl} />
      <TopScreen />
      <WeOffer />
      <OrderingButton />
      <About />
      <DoublePolished />
      <Specifications />
      <Prices emailToSend={emailToSend} requestUrl={requestUrl} />
      <Partner emailToSend={emailToSend} requestUrl={requestUrl} />
      <Footer requestUrl={requestUrl} />
    </div>
  );
}

export default LandingPage;
