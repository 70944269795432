import React, { useState } from "react";
import { BsRulers } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
function PriceCounter(props) {
  const requestUrl = props.requestUrl;
  const { t } = useTranslation();
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [price, setPrice] = useState(0);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const target = e.currentTarget;
    target.classList.add("_sendingFile");
    Promise.all([
      axios.get(`${requestUrl}price`),
      axios.get(`${requestUrl}currencies/rate`),
    ]).then((res) => {
      target.classList.remove("_sendingFile");
      const priceCurrency = res[0].data[0].currency;
      const usd = res[1].data[0].usd;
      const eur = res[1].data[0].eur;
      const uah = res[1].data[0].uah;
      const pln = res[1].data[0].pln;
      const currencyRate = () => {
        if (priceCurrency === "USD") {
          return usd;
        } else if (priceCurrency === "EUR") {
          return eur;
        } else if (priceCurrency === "UAH") {
          return uah;
        } else if (priceCurrency === "PLN") {
          return pln;
        }
      };
      setPrice(
        ((width * height * res[0].data[0].price) / 10000) * currencyRate()
      );
    });
  };
  function PriceRender() {
    if (price > 0) {
      return (
        <h3 style={{ margin: "0" }}>{`${t("priceCounter.price")} ${price.toFixed(2)} ${t("priceCounter.currency")}.`}</h3>
      );
    }
    return null;
  }

  function InfoRender() {
    if (price > 0) {
      return (
        <p className="priceCounter-text">{t("priceCounter.info.item1")}</p>
      );
    }
    return <p className="priceCounter-text">{t("priceCounter.info.item2")}</p>;
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} className="priceCounter-form-container">
        <div className="priceCounter-container">
          <h3 style={{ marginTop: "0" }}>{t("priceCounter.title")}</h3>
          <div className="priceCounter-inputWrapper">
            <input
              className="priceCounter-input"
              placeholder={t("priceCounter.input.width")}
              type="number"
              name="width"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            />
            <BsRulers style={{ fontSize: "25px", color: "#ff7a22" }} />
            <input
              className="priceCounter-input"
              placeholder={t("priceCounter.input.height")}
              type="number"
              name="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div className="priceCounter-priseWrapper">
            <button
              disabled={height * width <= 0}
              type="submit"
              className="priceCounter-button"
            >
              {t("priceCounter.button")}
            </button>
            <PriceRender />
          </div>
          <InfoRender />
        </div>
      </form>
      <Collapse className="sendFileForPrice-alert" in={alertErrorOpen}>
        <Alert
          severity="error"
          variant="filled"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertErrorOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {t("priceCounter.error")}
        </Alert>
      </Collapse>
    </React.Fragment>
  );
}

export default PriceCounter;
