import React from "react";
import { useTranslation } from "react-i18next";

function InputFile({
  onFileSelect,
  filesCout,
  setFilesCount = () => undefined,
}) {
  const { t } = useTranslation();

  const handleFileInput = (e) => {
    onFileSelect(e.target.files[0]);
    setFilesCount(e.target.files.length);
  };

  return (
    <div className="inputFile-wrapper">
      <input
        name="avatar"
        type="file"
        id="inputFile-iput"
        className="field field__file"
        multiple
        onChange={handleFileInput}
      />

      <label className="field__file-wrapper" htmlFor="inputFile-iput">
        <div className="field__file-fake">
          {filesCout
            ? t("inputFile.selectedFiles", { filesCout })
            : t("inputFile.input")}
        </div>
        <div className="field__file-button">{t("inputFile.button")}</div>
      </label>
    </div>
  );
}

export default InputFile;
