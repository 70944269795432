import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Root from "../src/client/Root";
import "../src/client/styles/index";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Loader from "./client/components/loader/";
import "./i18n";
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#ff7a22",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});
const requestUrl = "https://server.neoceram.in.ua/api/v1/";
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <Root requestUrl={requestUrl} />
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
