import React, { useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { BsRulers } from "react-icons/bs";
import { IoChevronForwardOutline } from "react-icons/io5";
import { IoChevronBackOutline } from "react-icons/io5";
import logoNovaPoshta from "../../styles/images/NovaPoshta.png";
import { IoStorefrontOutline } from "react-icons/io5";
import { IoCashOutline } from "react-icons/io5";
import { IoCardOutline } from "react-icons/io5";
import { IoSwapHorizontalOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import InputFile from "../../components/inputFile/";
import axios from "axios";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="orderingPage-tabPanelWrapper">{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OrderingPage(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend;
  const { t } = useTranslation();
  let navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
  const formDiv = useRef(null);
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [holesAmount, setHolesAmount] = useState("");
  const [diameter, setDiameter] = useState("");
  const [value, setValue] = useState(0);
  const [needHoles, setNeedHoles] = useState(false);
  const [glassAmount, setGlassAmount] = useState("1");
  const [standartShape, setStandartShape] = useState("selected");
  const [notStandartShape, setNotStandartShape] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [shippingMethod, setShippingMethod] = useState("Нова Пошта");
  const [paymentMethod, setPaymentMethod] = useState("Накладений платіж");
  const [city, setCity] = useState("");
  const [shippingDepartmentNumber, setShippingDepartmentNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [alertErrorOpen, setAlertErrorOpen] = React.useState(false);
  const [filesCout, setFilesCount] = useState(0);
  const [errMessageShow, setErrMessageShow] = useState(false);

  function validatePhone(phone) {
    const phoneArr = phone.split('')
    if (phoneArr[1] == 0) {
      setErrMessageShow(false)
      return true
    }
    setErrMessageShow(true)
    return false
  }
  function FirstNextButton() {
    if ((width > 0) & (height > 0)) {
      return (
        <button
          onClick={() => setValue(value + 1)}
          className="orderingPage-glass-nextButton"
        >
          <div></div>
          {t("orderingPage.nextButton")}
          <IoChevronForwardOutline
            style={{ fontSize: "25px", color: "white" }}
          />
        </button>
      );
    }
    return (
      <button disabled className="orderingPage-glass-nextButton-disabled">
        <div></div>
        {t("orderingPage.nextButton")}
        <IoChevronForwardOutline style={{ fontSize: "25px", color: "white" }} />
      </button>
    );
  }

  function SecondNextButton() {
    if ((phone.length > 13) & (name.length > 2) & validatePhone(phone)) {
      return (
        <button
          onClick={() => setValue(value + 1)}
          className="orderingPage-personalData-nextButton"
        >
          <div></div>
          {t("orderingPage.nextButton")}
          <IoChevronForwardOutline
            style={{ fontSize: "25px", color: "white" }}
          />
        </button>
      );
    }
    return (
      <button
        disabled
        className="orderingPage-personalData-nextButton-disabled"
      >
        <div></div>
        {t("orderingPage.nextButton")}
        <IoChevronForwardOutline style={{ fontSize: "25px", color: "white" }} />
      </button>
    );
  }

  function HolesChecked() {
    return (
      <div className="orderingPage-glass-holes-inputsWrapper">
        <label className="orderingPage-glass-holes-input-label" for="amount">
          {t("orderingPage.holes.amountLabel")}
          <input
            className="orderingPage-glass-holes-input"
            type="number"
            value={holesAmount}
            id="amount"
            onChange={(e) => setHolesAmount(e.target.value)}
          />
        </label>
        <label className="orderingPage-glass-holes-input-label" for="diameter">
          {t("orderingPage.holes.diametrLabel")}
          <input
            className="orderingPage-glass-holes-input"
            type="number"
            value={diameter}
            id="diameter"
            onChange={(e) => setDiameter(e.target.value)}
          />
        </label>
      </div>
    );
  }

  function HolesDisabled() {
    return (
      <div className="orderingPage-glass-holes-inputsWrapper">
        <label
          className="orderingPage-glass-holes-input-label-disabled"
          for="amount"
        >
          {t("orderingPage.holes.amountLabel")}
          <input
            disabled
            className="orderingPage-glass-holes-input-disabled"
            type="number"
            value={holesAmount}
            id="amount"
            onChange={(e) => setHolesAmount(e.target.value)}
          />
        </label>
        <label
          className="orderingPage-glass-holes-input-label-disabled"
          for="diameter"
        >
          {t("orderingPage.holes.diametrLabel")}
          <input
            disabled
            className="orderingPage-glass-holes-input-disabled"
            type="number"
            value={diameter}
            id="diameter"
            onChange={(e) => setDiameter(e.target.value)}
          />
        </label>
      </div>
    );
  }

  function HolesCheck() {
    if (needHoles === true) {
      return <HolesChecked />;
    }
    return <HolesDisabled />;
  }
  function BackButton() {
    return (
      <button
        onClick={() => setValue(value - 1)}
        className="orderingPage-personalData-backButton"
      >
        <IoChevronBackOutline style={{ fontSize: "25px", color: "white" }} />
        {t("orderingPage.backButton")}
        <div></div>
      </button>
    );
  }
  const handlePhone = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };
  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
  const handleSubmit = async () => {
    const target = formDiv.current;
    target.classList.add("_sending");
    try {
      const formData = new FormData();
      formData.append("shape", standartShape);
      formData.append("height", height);
      formData.append("width", width);
      formData.append("holesAmount", holesAmount);
      formData.append("diameter", diameter);
      formData.append("glassAmount", glassAmount);
      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("email", email);
      formData.append("shippingMethod", shippingMethod);
      formData.append("city", city);
      formData.append("shippingDepartmentNumber", shippingDepartmentNumber);
      formData.append("paymentMethod", paymentMethod);
      formData.append("file", selectedFile);
      formData.append("state", "Не оброблено❌");
      formData.append('emailToSend',emailToSend);
      await axios
        .post(`${requestUrl}send-email`, formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          window.location.href = "/OrderSuccess";
        });
    } catch (error) {
      target.classList.remove("_sending");
      console.log(error);
      setAlertErrorOpen(true);
    }
  };
  function OrderingButton() {
    return (
      <button onClick={handleSubmit} className="sendOrderButton-wrapper">
        <div className="sendOrderButton">
          <div></div>
          {t("orderingPage.orderingButton.part1")}
          <br />
          {t("orderingPage.orderingButton.part2")}
          <IoChevronForwardOutline
            style={{ fontSize: "25px", color: "white" }}
          />
        </div>
      </button>
    );
  }
  return (
    <div className="orderingPage-wrapper">
      <div ref={formDiv} className="orderingPage-container">
        <div className="orderingPage-header">
          <button onClick={handleClick} className="orderingPage-goBackButton">
            <IoArrowBackOutline style={{ fontSize: "30px" }} />
          </button>
          <h2 style={{ color: "#ff7a22" }}>{t("orderingPage.title")}</h2>
          <div></div>
        </div>
        <div className="orderingPage-tabs">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example"
            >
              <Tab
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                label={t("orderingPage.header.glass")}
                {...a11yProps(0)}
              />
              <Tab
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                label={t("orderingPage.header.personalData")}
                {...a11yProps(1)}
              />
              <Tab
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                label={t("orderingPage.header.deliveryPayment")}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="orderingPage-tabPanel">
              <div className="orderingPage-glass-shapeSelect">
                <p>{t("orderingPage.shapeChange.label")}</p>
                <div className="orderingPage-glass-shapeSelect-itemsWrapper">
                  <div
                    onClick={() => {
                      if (standartShape === "") {
                        setStandartShape("selected");
                        setNotStandartShape("");
                      } else {
                        setStandartShape("");
                      }
                    }}
                    className={
                      standartShape === ""
                        ? "orderingPage-glass-shapeSelect-item"
                        : "orderingPage-glass-shapeSelect-item-selected"
                    }
                  >
                    <h4 className="orderingPage-glass-shapeSelect-item-h4">
                      {t("orderingPage.shapeChange.item1.title")}
                    </h4>
                    <p>
                      {t("orderingPage.shapeChange.item1.text")}
                      <br />
                      {t("orderingPage.shapeChange.item1.text2")}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      if (notStandartShape === "") {
                        setNotStandartShape("selected");
                        setStandartShape("");
                      } else {
                        setNotStandartShape("");
                      }
                    }}
                    className={
                      notStandartShape === ""
                        ? "orderingPage-glass-shapeSelect-item"
                        : "orderingPage-glass-shapeSelect-item-selected"
                    }
                  >
                    <h4 className="orderingPage-glass-shapeSelect-item-h4">
                      {t("orderingPage.shapeChange.item2.title")}
                    </h4>
                    <p>
                      {t("orderingPage.shapeChange.item2.text")}
                      <br />
                      {t("orderingPage.shapeChange.item2.text2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="orderingPage-glass-widthHeight">
                <p style={{ marginTop: "0", color: "rgb(158, 158, 158)" }}>
                  {t("orderingPage.heightWidth.label")}
                </p>
                <div className="orderingPage-glass-widthHeight-inputsWrapper">
                  <input
                    className="orderingPage-glass-widthHeight-input"
                    placeholder={t("orderingPage.heightWidth.placeholderWidth")}
                    type="number"
                    name="width"
                    value={width}
                    onChange={(e) => setWidth(e.target.value)}
                  />
                  <BsRulers style={{ fontSize: "25px", color: "#ff7a22" }} />
                  <input
                    className="orderingPage-glass-widthHeight-input"
                    placeholder={t(
                      "orderingPage.heightWidth.placeholderHeight"
                    )}
                    type="number"
                    name="height"
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </div>
              </div>
              <div className="orderingPage-glass-formFooter">
                <div className="orderingPage-glass-holes">
                  <input
                    type="checkbox"
                    checked={needHoles}
                    onChange={() => setNeedHoles(!needHoles)}
                    className="custom-checkbox"
                    id="happy"
                  />
                  <label for="happy">
                    {t("orderingPage.holes.checkboxLabel")}
                  </label>
                  <HolesCheck />
                </div>
                <div className="orderingPage-glass-amount">
                  <label
                    className="orderingPage-glass-amount-input-label"
                    for="amount"
                  >
                    {t("orderingPage.amount")}
                    <input
                      className="orderingPage-glass-amount-input"
                      type="number"
                      value={glassAmount}
                      id="amount"
                      onChange={(e) => setGlassAmount(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="orderingPage-glass-widthHeight">
                <p style={{ marginTop: "0", color: "rgb(158, 158, 158)" }}>
                  {t("orderingPage.inputFile")}
                </p>
                <InputFile
                  onFileSelect={(file) => setSelectedFile(file)}
                  filesCout={filesCout}
                  setFilesCount={setFilesCount}
                />
              </div>
              <FirstNextButton />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="orderingPage-tabPanel">
              <div className="orderingPage-personalData-name">
                <label
                  className="orderingPage-personalData-input-label"
                  for="name"
                >
                  &nbsp;&nbsp;{t("orderingPage.nameLabel")}
                  <input
                    className="orderingPage-personalData-input"
                    type="text"
                    placeholder={t("orderingPage.namePlaceholder")}
                    value={name}
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
              </div>
              <div className="orderingPage-personalData-contacts">
                <label
                  className="orderingPage-personalData-input-label"
                  for="phone"
                >
                  &nbsp;&nbsp;{t("orderingPage.phoneLabel")}
                  <input
                    className="orderingPage-personalData-input"
                    placeholder="(099)-999-9999"
                    value={phone}
                    id="phone"
                    onChange={(e) => handlePhone(e)}
                  />
                  {errMessageShow?<p style={{margin:"0",marginLeft:"10px",fontSize:"12px"}}>{t("orderingPage.phoneError")}</p>:null}
                </label>
                <label
                  className="orderingPage-personalData-input-label"
                  for="email"
                >
                  &nbsp;&nbsp;Email
                  <input
                    className="orderingPage-personalData-input"
                    type="email"
                    placeholder={t("orderingPage.emailPlaceholder")}
                    value={email}
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
              </div>
              <div className="orderingPage-personalData-buttons-container">
                <BackButton />
                <SecondNextButton />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="orderingPage-tabPanel">
              <div className="orderingPage-shippingPayment-container">
                <h3 className="orderingPage-shippingPayment-title">
                  {t("orderingPage.shipping.title")}
                </h3>
                <div className="orderingPage-shippingPayment-shippingMethod-container">
                  <div className="orderingPage-shippingPayment-shippingMethod-radioWrapper">
                    <input
                      className="custom-radio"
                      type="radio"
                      id="shippingMethod"
                      name="shippingMethod"
                      value="Нова Пошта"
                      checked={shippingMethod === "Нова Пошта"}
                      onClick={() => setShippingMethod("Нова Пошта")}
                    />
                    <label for="shippingMethod"></label>
                  </div>
                  <div className="orderingPage-shippingPayment-shippingMethod-textWrapper">
                    <h4 className="orderingPage-shippingPayment-shippingMethod-title">
                      {t("orderingPage.shipping.item1")}
                    </h4>
                  </div>
                  <img
                    alt=""
                    src={logoNovaPoshta}
                    className="orderingPage-shippingPayment-shippingMethod-img"
                  />
                </div>
                <div className="orderingPage-shippingPayment-shippingMethod-container">
                  <div className="orderingPage-shippingPayment-shippingMethod-radioWrapper">
                    <input
                      className="custom-radio"
                      type="radio"
                      id="shippingMethod2"
                      name="shippingMethod"
                      value="Самовивіз"
                      checked={shippingMethod === "Самовивіз"}
                      onClick={() => setShippingMethod("Самовивіз")}
                    />
                    <label for="shippingMethod2"></label>
                  </div>
                  <div className="orderingPage-shippingPayment-shippingMethod-textWrapper">
                    <h4 className="orderingPage-shippingPayment-shippingMethod-title">
                      {t("orderingPage.shipping.item3.title")}
                    </h4>
                    <p style={{ margin: "0", fontSize:"13px" }}>
                      {t("orderingPage.shipping.item3.text")}
                    </p>
                  </div>
                  <IoStorefrontOutline style={{ fontSize: "40px" }} />
                </div>
                {shippingMethod === "Самовивіз" ? null : (
                  <div className="orderingPage-shippingPayment-shippingDetails-container">
                    <label
                      className="orderingPage-shippingCity-label"
                      for="shippingCity"
                    >
                      {t("orderingPage.shipping.cityLabel")}
                      <input
                        className="orderingPage-shippingCity-input"
                        value={city}
                        id="shippingCity"
                        name="city"
                        type="text"
                        placeholder={t("orderingPage.shipping.cityPlaceholder")}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </label>
                    <label
                      className="orderingPage-shippingDepartmentNumber-label"
                      for="shippingDepartmentNumber"
                    >
                      {t("orderingPage.shipping.department")}
                      <input
                        className="orderingPage-shippingDepartmentNumber-input"
                        value={shippingDepartmentNumber}
                        id="shippingDepartmentNumber"
                        type="number"
                        onChange={(e) =>
                          setShippingDepartmentNumber(e.target.value)
                        }
                      />
                    </label>
                  </div>
                )}
              </div>
              <div className="orderingPage-shippingPayment-container">
                <h3 className="orderingPage-shippingPayment-title">
                  {t("orderingPage.payment.title")}
                </h3>
                <div className="orderingPage-shippingPayment-paymentMethod-container">
                  <div className="orderingPage-shippingPayment-paymentMethod-radioWrapper">
                    <input
                      className="custom-radio"
                      type="radio"
                      id="paymentMethod"
                      name="paymentMethod"
                      value="Готівка"
                      checked={paymentMethod === "Готівка"}
                      onClick={() => setPaymentMethod("Готівка")}
                    />
                    <label for="paymentMethod"></label>
                  </div>
                  <IoCashOutline style={{ fontSize: "40px" }} />
                  <div className="orderingPage-shippingPayment-paymentMethod-textWrapper">
                    <h4 className="orderingPage-shippingPayment-paymentMethod-title">
                      {t("orderingPage.payment.item1.title")}
                    </h4>
                    <p style={{ margin: "0" }}>
                      {t("orderingPage.payment.item1.text")}
                    </p>
                  </div>
                </div>
                <div className="orderingPage-shippingPayment-paymentMethod-container">
                  <div className="orderingPage-shippingPayment-paymentMethod-radioWrapper">
                    <input
                      className="custom-radio"
                      type="radio"
                      id="paymentMethod1"
                      name="paymentMethod"
                      value="На картку"
                      checked={paymentMethod === "На картку"}
                      onClick={() => setPaymentMethod("На картку")}
                    />
                    <label for="paymentMethod1"></label>
                  </div>
                  <IoCardOutline style={{ fontSize: "40px" }} />
                  <div className="orderingPage-shippingPayment-paymentMethod-textWrapper">
                    <h4 className="orderingPage-shippingPayment-paymentMethod-title">
                      {t("orderingPage.payment.item2.title")}
                    </h4>
                    <p style={{ margin: "0" }}>
                      {t("orderingPage.payment.item2.text")}
                    </p>
                  </div>
                </div>
                <div className="orderingPage-shippingPayment-paymentMethod-container">
                  <div className="orderingPage-shippingPayment-paymentMethod-radioWrapper">
                    <input
                      className="custom-radio"
                      type="radio"
                      id="paymentMethod2"
                      name="paymentMethod"
                      value="Накладений платіж"
                      checked={paymentMethod === "Накладений платіж"}
                      onClick={() => setPaymentMethod("Накладений платіж")}
                    />
                    <label for="paymentMethod2"></label>
                  </div>
                  <IoSwapHorizontalOutline style={{ fontSize: "40px" }} />
                  <div className="orderingPage-shippingPayment-paymentMethod-textWrapper">
                    <h4 className="orderingPage-shippingPayment-paymentMethod-title">
                      {t("orderingPage.payment.item3.title")}
                    </h4>
                    <p style={{ margin: "0" }}>
                      {t("orderingPage.payment.item3.text")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="orderingPage-personalData-buttons-container">
                <BackButton />
                <OrderingButton />
              </div>
              <Collapse className="orderingPage-alert" in={alertErrorOpen}>
                <Alert
                  severity="error"
                  variant="filled"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlertErrorOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {t("orderingPage.alertError")}
                </Alert>
              </Collapse>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default OrderingPage;
