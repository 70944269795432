import React,{useEffect,useState} from "react";
import LandingPage from "../client/pages/landingPage/";
import OrderingPage from "../client/pages/orderingPage/";
import OrderSuccessPage from "../client/pages/orderSuccessPage/";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";

function Root(props) {
  const requestUrl = props.requestUrl;
  const [emailToSend, setEmailToSend] = useState("");
  useEffect(() => {
      axios
        .get(`${requestUrl}companyDataUa`)
        .then((response) => {
          setEmailToSend(response.data[0].email);
        });
   
  },[]);
  return (
    <div className="App">
      <Router>
        <main className="root-main">
          <Routes>
            <Route path="/" element={<LandingPage emailToSend={emailToSend} requestUrl={requestUrl} />} />
            <Route
              path="/OrderingPage"
              element={<OrderingPage emailToSend={emailToSend} requestUrl={requestUrl} />}
            />
            <Route path="/OrderSuccess" element={<OrderSuccessPage />} />
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default Root;
