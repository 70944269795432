import React from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

function OrderingButton() {
  const { t } = useTranslation();
  return (
    <ScrollAnimation animateIn="zoomIn">
      <div className="orderingButton-container">
        <Link to="/OrderingPage" className="orderingButton">
          <div></div>
          {t("orderingButton")}
          <div className="orderingButton-iconWrapper">
            <IoChevronForwardOutline style={{ fontSize: "25px" }} />
          </div>
        </Link>
      </div>
    </ScrollAnimation>
  );
}

export default OrderingButton;
