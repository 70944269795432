import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

function Partner(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend;
  const { t } = useTranslation();
  const [alertSuccessOpen, setAlertSuccessOpen] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onBlur",
  });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: "", email: "", phone: "", partnerText: "" });
    }
  }, [formState, reset]);

  const submitForm = async (data) => {
    data.emailToSend = emailToSend;
    setAlertSuccessOpen(false);
    setAlertErrorOpen(false);
    try {
      await axios.post(`${requestUrl}send-partner-email`, data).then((res) => {
        console.log(res);
        setAlertSuccessOpen(true);
      });
    } catch (error) {
      console.log(error);
      setAlertErrorOpen(true);
    }
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      const a = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      return a;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  return (
    <div className="partner-wrapper" id="section5">
      <div className="partner-container">
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOut="fadeOut"
          className="partner-textContainer"
        >
          <h2 style={{ marginTop: "0", color: "#ff7a22" }}>
            {t("partner.title")}
          </h2>
          <p className="partner-text">{t("partner.text")}</p>
        </ScrollAnimation>
        <div className="partner-formContainer">
          <form
            onSubmit={handleSubmit(submitForm)}
            className={
              isSubmitting ? "partner-form _sendingFile" : "partner-form"
            }
          >
            <label className="partner-inputLabel">
              &nbsp;&nbsp;{t("partner.form.nameLabel")}
              <input
                placeholder={t("partner.form.namePlaceholder")}
                className="partner-input"
                type="text"
                name="name"
                {...register("name", {
                  required: `${t("partner.form.errors.name.required")}`,
                  minLength: {
                    value: 3,
                    message: `${t("partner.form.errors.name.minLength")}`,
                  },
                })}
              />
              {errors.name && (
                <p className="partner-input-error">
                  &nbsp;&nbsp;{errors.name.message || "Error!"}
                </p>
              )}
            </label>
            <label className="partner-inputLabel">
              &nbsp;&nbsp;{t("partner.form.emailLabel")}
              <input
                placeholder={t("partner.form.emailPlaceholder")}
                className="partner-input"
                name="email"
                type="email"
                {...register("email", {
                  required: `${t("partner.form.errors.email.required")}`,
                  pattern: {
                    value:
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    message: `${t("partner.form.errors.email.minLength")}`,
                  },
                })}
              />
              {errors.email && (
                <p className="partner-input-error">
                  &nbsp;&nbsp;{errors.email.message || "Error!"}
                </p>
              )}
            </label>
            <label className="partner-inputLabel">
              &nbsp;&nbsp;Телефон
              <input
                className="partner-input"
                name="phone"
                placeholder="(099)-999-9999"
                {...register("phone", {
                  minLength: {
                    value: 14,
                    message: `${t("partner.form.errors.phone")}`,
                  },
                })}
                onChange={(e) => {
                  setValue("phone", formatPhoneNumber(e.target.value));
                }}
              />
              {errors.phone && (
                <p className="partner-input-error">
                  &nbsp;&nbsp;{errors.phone.message || "Error!"}
                </p>
              )}
            </label>
            <label className="partner-inputLabel">
              &nbsp;&nbsp;{t("partner.form.infoLabel")}
              <textarea
                className="partner-input info"
                type="text"
                {...register("partnerText")}
              />
            </label>
            <button
              disabled={!isValid}
              type="submit"
              className="partner-formButton"
            >
              {t("partner.form.button.part1")}
              <br />
              {t("partner.form.button.part2")}
            </button>
          </form>
          <Collapse className="sendFileForPrice-alert" in={alertSuccessOpen}>
            <Alert
              severity="success"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertSuccessOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("inputFile.alertSuccess")}
            </Alert>
          </Collapse>
          <Collapse className="sendFileForPrice-alert" in={alertErrorOpen}>
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertErrorOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {t("inputFile.alertError")}
            </Alert>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default Partner;
