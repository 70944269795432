import React from "react";
import { IoCutOutline } from "react-icons/io5";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { IoRocketOutline } from "react-icons/io5";
import { IoRibbonOutline } from "react-icons/io5";
import { IoArrowUpCircleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import { useMediaQuery } from "react-responsive";
function WeOffer() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1025px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const { t } = useTranslation();
  return (
    <div className="weOffer-wrapper" id="section2">
      <div className="weOffer-container">
        <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOut">
          <h2 style={{ color: "#ff7a22" }}>{t("weOffer.title")}</h2>
        </ScrollAnimation>
        <div className="weOffer-allOffers-container">
          <div className="weOffer-twoOffers-wrapper">
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutLeft"
              className="weOffer-offer-container"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="weOffer-offer-flipper">
                <div className="weOffer-offer-firstSide">
                  <div className="weOffer-offer-iconWrapper">
                    <IoCutOutline
                      style={{ fontSize: "60px", color: "#ff7a22" }}
                    />
                  </div>
                  <h3>{t("weOffer.element1.title")}</h3>
                </div>
                <div className="weOffer-offer-secondSide">
                  <ul>
                    <li>{t("weOffer.element1.item1")}</li>
                    <li>{t("weOffer.element1.item2")}</li>
                    <li>{t("weOffer.element1.item3")}</li>
                    <li>{t("weOffer.element1.item4")}</li>
                    <li>{t("weOffer.element1.item5")}</li>
                  </ul>
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              className="weOffer-offer-container"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="weOffer-offer-flipper">
                <div className="weOffer-offer-firstSide">
                  <div className="weOffer-offer-iconWrapper">
                    <IoExtensionPuzzleOutline
                      style={{ fontSize: "60px", color: "#ff7a22" }}
                    />
                  </div>
                  <h3>
                    {t("weOffer.element2.title.part1")}
                    <br />
                    {t("weOffer.element2.title.part2")}
                  </h3>
                </div>
                <div className="weOffer-offer-secondSide">
                  {" "}
                  <ul>
                    <li>{t("weOffer.element2.item1")}</li>
                    <li>{t("weOffer.element2.item2")}</li>
                  </ul>
                </div>
              </div>
            </ScrollAnimation>
          </div>

          <div className="weOffer-twoOffers-wrapper">
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              className="weOffer-offer-container"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="weOffer-offer-flipper">
                <div className="weOffer-offer-firstSide">
                  <div className="weOffer-offer-iconWrapper">
                    <IoRocketOutline
                      style={{ fontSize: "60px", color: "#ff7a22" }}
                    />
                  </div>
                  <h3>{t("weOffer.element3.title")}</h3>
                </div>
                <div className="weOffer-offer-secondSide">
                  <ul>
                    <li>{t("weOffer.element3.item1")}</li>
                    <li>{t("weOffer.element3.item2")}</li>
                  </ul>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              className="weOffer-offer-container"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="weOffer-offer-flipper">
                <div className="weOffer-offer-firstSide">
                  <div className="weOffer-offer-iconWrapper">
                    <IoRibbonOutline
                      style={{ fontSize: "60px", color: "#ff7a22" }}
                    />
                  </div>
                  <h3>{t("weOffer.element4.title")}</h3>
                </div>
                <div className="weOffer-offer-secondSide">
                  {t("weOffer.element4.item1")}
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
        {isTabletOrMobile && <p
          style={{
            display: "flex",
            alignItems: "center",
            marginTop:"50px"
          }}
        >
          <IoArrowUpCircleOutline
            style={{
              fontSize: "30px",
              color: "#ff7a22",
              marginRight: "10px",
            }}
          />
          {t("weOffer.infoMobile")}
          <IoArrowUpCircleOutline
            style={{ fontSize: "30px", color: "#ff7a22", marginLeft: "10px" }}
          />
        </p>}
        {isDesktopOrLaptop && <p
          style={{
            display: "flex",
            alignItems: "center",
            marginTop:"50px"
          }}
        >
          <IoArrowUpCircleOutline
            style={{
              fontSize: "30px",
              color: "#ff7a22",
              marginRight: "10px",
            }}
          />
          {t("weOffer.infoDesktop")}
          <IoArrowUpCircleOutline
            style={{ fontSize: "30px", color: "#ff7a22", marginLeft: "10px" }}
          />
        </p> }
          
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default WeOffer;
