import React from "react";
import logo from "../../styles/images/Neoceram-Pharma_Logo.png";
import ScrollAnimation from "react-animate-on-scroll";
import { BsMouse } from "react-icons/bs";
import { BsArrowDownShort } from "react-icons/bs";
import { useTranslation } from "react-i18next";
function TopScreen() {
  const { t } = useTranslation();
  return (
    <div className="topScreen-container" id="section1">
      <div className="topScreen-center-container">
      <ScrollAnimation animateIn="fadeIn">
        <img src={logo} alt={"logo"} className="topScreen-logo" />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <h2 className="topScreen-tagline">
            {t("topScreen.tagline.part1")}
            <br />
            {t("topScreen.tagline.part2")}
          </h2>
        </ScrollAnimation>
      </div>
      <div className="topScreen-mouse-container">
        <BsMouse style={{ fontSize: "30px" }} />
        <BsArrowDownShort style={{ fontSize: "30px" }} />
      </div>
    </div>
  );
}

export default TopScreen;
