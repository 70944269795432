import React, { useState, useEffect } from "react";
import logo1 from "../../styles/images/logo-profservice.png";
import logo2 from "../../styles/images/Neoceram-Pharma_Logo.png";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import LangSelect from "../langSelect/";
import axios from "axios";
function Footer(props) {
  const requestUrl = props.requestUrl;
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (i18n.language === "ua") {
      axios
        .get(`${requestUrl}companyDataUa`)
        .then((response) => {
          setPhone(response.data[0].phone);
          setEmail(response.data[0].email);
          setAddress(response.data[0].address);
          setCompanyName(response.data[0].companyName);
        });
    } else {
      axios
        .get(`${requestUrl}companyDataRu`)
        .then((response) => {
          setPhone(response.data[0].phone);
          setEmail(response.data[0].email);
          setAddress(response.data[0].address);
          setCompanyName(response.data[0].companyName);
        });
    }
  }, [i18n.language]);

  return (
    <div className="footer-wrapper" id="section6">
      <div className="footer-container">
        <div className="footer-content-container">
          <div className="footer-contactsContainer">
            <div className="footer-contacts-part1">
              <h2 className="footer-contacts-title">{t("contacts.title")}</h2>
              <p className="footer-contacts-address">{companyName}</p>
              <p className="footer-contacts-address">{address}</p>
            </div>
            <div className="footer-contacts-part2">
              <div className="footer-contacts-buttonWrapper">
                {t("contacts.text.part2.item1")}

                <a className="footer-contacts-buttonLink" href={`tel:${phone}`}>
                  {phone}
                </a>
              </div>
              <div className="footer-contacts-buttonWrapper">
                {t("contacts.text.part2.item2")}
                <a
                  className="footer-contacts-buttonLink"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-menuContainer">
          <ul className="footer-nav-items">
            <li className="footer-nav-item">
              <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("footerMenu.item1")}
              </Link>
            </li>
            <li className="footer-nav-item">
              <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("footerMenu.item2")}
              </Link>
            </li>
            <li className="footer-nav-item">
              <Link
                activeClass="active"
                to="section5"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
              >
                {t("footerMenu.item3.part1")}
                <br />
                {t("footerMenu.item3.part2")}
              </Link>
            </li>
            <li className="footer-nav-item">
              <LangSelect />
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-logoContainer">
        <img className="footer-logo1" src={logo1} alt={"logo1"} />
        <div className="footer-logoStick"></div>
        <img className="footer-logo2" src={logo2} alt={"logo2"} />
      </div>
      <p style={{ color: "#5c5c5c", marginTop: "150px" }}>{companyName}</p>
    </div>
  );
}

export default Footer;
