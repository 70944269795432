import React from "react";
import PriceCounter from "../priceCounter/";
import OrderingButton from "../orderingButton/";
import SendFileForPrice from "../sendFileForPrice/";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
function Price(props) {
  const requestUrl = props.requestUrl;
  const emailToSend = props.emailToSend; 
  const { t } = useTranslation();
  return (
    <div className="price-wrapper" id="section4">
      <div className="price-container">
        <div className="price-response-container">
          <PriceCounter requestUrl={requestUrl} />
          <SendFileForPrice emailToSend={emailToSend} requestUrl={requestUrl} />
        </div>
        <div className="price-text-container">
          <ScrollAnimation animateIn="fadeInRight" animateOut="fadeOut">
            <h2 style={{ marginTop: "0", color: "#ff7a22" }}>
              {t("price.title")}
            </h2>
            <p>{t("price.text")}</p>
          </ScrollAnimation>
          <OrderingButton />
        </div>
      </div>
    </div>
  );
}

export default Price;
