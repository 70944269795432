import React from "react";
import image from "../../styles/images/doublePolished.png";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
function DoublePolished() {
  const { t } = useTranslation();
  return (
    <div className="doublePolished-wrapper">
      <div className="doublePolished-container">
        <ScrollAnimation
          className="doublePolished-container1"
          animateIn="fadeInLeft"
          animateOut="fadeOut"
        >
          <img className="doublePolished-image" src={image} alt="" />
        </ScrollAnimation>
        <ScrollAnimation
          className="doublePolished-container2"
          animateIn="fadeInRight"
          animateOut="fadeOut"
        >
          <h2 className="doublePolished-title">{t("doublePolished.title")}</h2>
          <p>{t("doublePolished.text")}</p>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default DoublePolished;
