import React from "react";
import logoNeoceram from "../../styles/images/Neoceram-Logo-black.png";
import logoProfService from "../../styles/images/logo-profservise-color.png";
import Slider from "../slider/";
import ScrollAnimation from "react-animate-on-scroll";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import MobileSlider from "../mobileSlider";
function About() {
  const { t } = useTranslation();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1225px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div className="about-wrapper" id="section3">
      <div className="about-container">
        <div className="about-text">
          <ScrollAnimation animateIn="fadeInLeft" animateOut="fadeOut">
            <h2 style={{ color: "#ff7a22" }}>{t("about.title")}</h2>
            <p>{t("about.text1")}</p>
          </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
          <img alt="" src={logoNeoceram} />
        </ScrollAnimation>
      </div>
      <div className="about-container2">
        <img alt="" className="about-logo" src={logoProfService} />
        <ScrollAnimation
          className="about-text"
          animateIn="fadeInRight"
          animateOut="fadeOut"
        >
          <p>{t("about.text2")}</p>
        </ScrollAnimation>
      </div>
      {isTabletOrMobile && <MobileSlider />}
      {isDesktopOrLaptop && <Slider />}
    </div>
  );
}

export default About;
