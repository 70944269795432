import React, { useMemo } from "react";
import slide1 from "../../styles/images/slide1.jpg";
import slide2 from "../../styles/images/slide2.jpg";
import slide3 from "../../styles/images/slide3.jpg";
import slide4 from "../../styles/images/slide4.jpg";
import slide5 from "../../styles/images/slide5.jpg";
import slide6 from "../../styles/images/slide6.jpg";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function MobileSlider() {
  const { t, i18n } = useTranslation();
  const slides = useMemo(() => {
    return [
      {
        id: "0",
        image: slide1,
        discription: t("slides.slide1"),
      },
      {
        id: "1",
        image: slide2,
        discription: t("slides.slide2"),
      },
      {
        id: "2",
        image: slide3,
        discription: t("slides.slide3"),
      },
      {
        id: "3",
        image: slide4,
        discription: t("slides.slide4"),
      },
      {
        id: "4",
        image: slide5,
        discription: t("slides.slide5"),
      },
      {
        id: "5",
        image: slide6,
        discription: t("slides.slide6"),
      },
    ];
  }, [t, i18n.language]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="mobileSlider-wrapper">
      <Slider {...settings}>
        {slides.map((slides, index) => {
          return (
            <div key={index} className="mobileSlider-slide-wrapper">
              <div className="mobileSlider-slide">
                <img className="slider-item-img" src={slides.image} alt="" />
                <p className="slider-item-text">{slides.discription}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
export default MobileSlider;
