import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

export default function MobileHeaderMenu(props) {
  const requestUrl = props.requestUrl;
  const { t,i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState(0);
  const [phone, setPhone] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, [offset]);

  useEffect(() => {
    if (i18n.language === "ua") {
      axios
        .get(`${requestUrl}companyDataUa`)
        .then((response) => {
          setPhone(response.data[0].phone);
        });
    } else {
      axios
        .get(`${requestUrl}companyDataRu`)
        .then((response) => {
          setPhone(response.data[0].phone);
        });
    }
  }, [i18n.language]);

  return (
    <div>
      <IconButton
        style={{
          color: "white",
          position: "fixed",
          top: "10px",
          backgroundColor: "#ff7a22",
        }}
        className={offset > 0 ? "-scroll" : "iconButton-header"}
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuRoundedIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onBlur={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "80%",
          },
        }}
      >
      <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
      <a
            className="header-contacts-buttonLink"
            href={`tel:${phone}`}
          >
          {phone}
          </a>
          </MenuItem>
        <Link
          activeClass="active"
          to="section2"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
            {t("header.weOffer")}
          </MenuItem>
        </Link>
        <Link
          activeClass="active"
          to="section3"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
            {t("header.about")}
          </MenuItem>
        </Link>
        <Link
          activeClass="active"
          to="section4"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
            {t("header.prices")}
          </MenuItem>
        </Link>
        <Link
          activeClass="active"
          to="section5"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
            {t("header.cooperation.part1")}&nbsp;
            {t("header.cooperation.part2")}
          </MenuItem>
        </Link>
        <Link
          activeClass="active"
          to="section6"
          spy={true}
          smooth={true}
          offset={-70}
          duration={1000}
        >
          <MenuItem
            style={{ display: "flex", justifyContent: "center" }}
            onClick={handleClose}
          >
            {t("header.contacts")}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}
