import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function Loader() {
  return (
    <div className="loader-wrapper">
    <PulseLoader color={"#ff7a22"}  size={30} />
    </div>
  );
}

export default Loader;
